/* body section */
.gen-section {
  background-color: #ffffff;
}

:root {
  --main-color: #0055d4;
  --main-bg-color: black;
  --secondary-color: #ffd700;
  --main-backdrop: #f4f7f5;
  --third-bg-color: #ffffff;
}

@font-face {
  font-family: 'UKNumberPlate';
  src: url("./assets/single-elements/font/UKNumberPlate.ttf") format('truetype'); /* Replace 'path/to' with the actual path to your font file */
}

.gen-section2 {
  background-color: #ffffff;
}

.custom-container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.uk-number-plate {
  font-family: 'UKNumberPlate', sans-serif;
}

/* Best Deal */
.best-deal {
  background-color: "white";
}

.header-section .logo-navbar {
  width: 12rem !important;
  height: 1.5rem !important;
}

.header-section .container .inner-container{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  /* padding-top: 2.3em; */
  /* padding: 2.3em 0; */
}

#navbar-second-row {
  padding: 2px;
  font-weight: 600;
  background-color: #1a1a1a;
  border-bottom: 1px solid #000000;
  /*border-top: 2px solid #ffd700;*/
}

#navbar-first-row {
  padding-left: 1px !important;
  padding-right: 0 !important;
  padding-top: 0px
}

.header-section ul li {
  display: inline-block;
  padding: 10px;
}

.header-section {
  font-size: 15px;
  background-color: #f0f0f0;
}

/*.row {*/
/*  margin-right: 0px;*/
/*  margin-left: 0px;*/
/*}*/
/* .header-section ul li {
  padding: 10px;
} */
#header {
  border-bottom: 1px solid grey;
}

.header-section ul li a {
  padding-bottom: 5px;
}

.header-section ul li a:hover {
  color: #3e5067;
  border-bottom: 2px solid var(--secondary-color);
}

.top-nav-tab.actived {
  color: #3e5067;
  border-bottom: 2px solid var(--secondary-color);
}
#card-mobile-row {
  margin-left: 1px;
}
.header-section ul li img {
  /*margin-right: 5px;*/
}

.header-section ul li a:focus {
  color: #3e5067;
  border-bottom: 2px solid #3e5067;
}

.header-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 55px;
}

.transparent-polygon {
  z-index: 10;
  opacity: 0.1;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -8rem;
  width: 90%;
  height: 65px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 0% 100%);
  clip-path: polygon(0 0, 90% 0%, 0% 100%);
}

.header-left {
  display: flex;
  background-color: #fff;
  /* width: 100%; */
  /* flex: 0.2; */
  /*padding-left: calc((100vw - 1150px + 4px) / 2);*/
  /*align-items: center;*/
  justify-content: flex-end;
}

.header-right {
  background: #fff;
  /* IE6-9 */
  width: 100%;
  /*padding-right: calc((100vw - 1150px + 15px) / 2);*/
}

.logo-left {
  /*border: 1px solid #fff;*/
  border-radius: 2px;
  color: #ccc;
  font-size: 24px;
  font-weight: 900;
  /*padding-left: 0.4rem;*/
  /*padding-right: 0.4rem;*/
  margin: 25px 2px 0 5px;
  font-style: italic;
}

.logo-right {
  color: #3e87fd;
  font-size: 24px;
  font-weight: 900;
  /*text-shadow: 0 1px, 1px 0, 1px 1px;*/
}

.logo-right-span {
  height: 88px !important;
  padding-left: 0px;
  margin-top: 16px;
}

.my-account {
  padding-right: 0 !important;
}

.social-icons {
  padding: 0px;
  /*margin-right: 20px !important;*/
  margin-left: 25px;
  margin-top: 5px;
  width: 15px;
  height: 15px;
}

@media (min-width: 992px) {
  #mainNav {
    height: 70px;
    background-color: #6fa8dd;
    border-color: #6fa8dd;
  }

  #mainNav .navbar-brand {
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 0px;
  }

  #mainNav .navbar-brand:hover {
    color: #fff;
  }

  #mainNav .navbar-brand:active {
    color: #fff;
  }

  #mainNav .navbar-brand:focus {
    color: #fff;
  }

  #mainNav .navbar-nav > li.nav-item > a.nav-link {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
     border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  #mainNav .navbar-nav > li.nav-item > a.nav-link:hover {
    color: #fff;
  }

  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
    color: #fff;
  }
}
.center > div > input {
  font-size: 13px;
}
.center > div {
  padding: 3px;
}
.headerbg {
  /*background-image: url("./assets/images/display-pic2.png");*/
  /*background-color: var(--main-color);*/
  /*background: linear-gradient(110deg, var(--main-color) 60%, var(--secondary-color) 60%);*/
  /*#722300 0%, #ff4900*/
  background: radial-gradient(ellipse farthest-corner at 0 150%,#576edc 0%, #3b89ff 60%, #f6f6f6 60%);
  /*background: radial-gradient(ellipse farthest-corner at 0 140%, #3c84cc 0%, #316dc2 70%, #4e95d3 70%);*/
  background-position: center center;
  background-size: cover;
  border-radius: 0px;
  padding: 64px 0px 88px 0px;
}

.navbar-toggler {
  border-color: transparent !important;
  margin-right: 1em;
}

.navbar-toggler:focus {
  outline: none !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgb(56,56,56)' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.cart {
  width: 15px;
  vertical-align: -webkit-baseline-middle;
}

.vertical-line {
  border-left: 1px solid #dcdcdc;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
  width: 2px;
  margin-top: 14px;
}

.bottom-badges .vertical-line {
  height: 35px;
  margin-top: 5px;
  margin-right: 30px;
  margin-left: 5px;
}

.fancy {
  vertical-align: -webkit-baseline-middle;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
}

a.fancy:hover {
  /*color: #003398;*/
  /*!*background-color: #039800 !important;*!*/
  /*!*color: #fff;*!*/
  /*border-color: #16980b;*/
}

.nav-icon {
  width: 15px;
  vertical-align: sub;
}

.link-icons {
  width: 25px;
  vertical-align: sub;
}

.supporter-programme-recruits-pane {
  background-image: url("./assets/single-elements/money-connected-team.jpg");
  background-color:
  rgba(5, 154, 154, 0.65);
  background-position: center top;
}

.header-bottom {
  padding-top: 0px;
  padding-bottom: 5px;
}

.smallheaderbg {
  padding-top: 8rem;
  padding-bottom: calc(8rem - 112px);
  background-image: url("./assets/images/header.jpg");
  background-position: center center;
  background-size: cover;
}

.smallheaderbg h1 {
  font-size: 3rem;
}

.smallheaderbg p {
  font-weight: 300;
  font-size: 22px;
}

.advance-search {
  font-size: 11px;
  font-weight: 0px;
  color: #a9a9a9;
  float: right;
}

::selection {
  color: #fff;
  background: #6fa8dd;
  text-shadow: none;
}

.text-faded {
  color: #cdcdcd;
}

.btn {
  font-weight: 700;
  border: none;
  border-radius: 2px;
  font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
}

.btn-primary {
  background-color: var(--main-bg-color);
  border-radius: 3px;
}

.btn-primary:hover {
  background-color: var(--main-bg-color) ;
  color: #dedede!important;
  border-color: var(--main-bg-color);
}

.btn-primary:focus {
  color: #fff;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
}

.btn-primary:active {
  color: #fff;
  background-color: var(--main-bg-color) !important;
}

.btn-light {
  color: #3e5067;
}

.btn-light:hover {
  color: #3e5067;
}

#search-form-div {
  background-color: #fff;
  border-radius: 8px;
}

.gen-section {
}

.headings-div {
  /*background-color: #6fa8dd;*/
  /*height: 40px;*/
}

.headings {
  position: relative;
  top: 6px;
  font-weight: 900;
  padding-top: 5px;
  padding-bottom: 16px;
  color: var(--main-bg-color)
}

.card-header {
  background-color: #0055d4;
  color: white;
}

.card-header:first-child {
  border-radius: 4px 4px 0 0;
  font-weight: 900;
  text-align: center;
}

#shopbymake {
  font-size: 20px;
}

.thumbnail-div {
  height: 140px;
  width: 140px;
  background-color: #6fa8dd;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  /*color: black;*/
}

.list-text:hover {
  color: #528fc8;
}

.logos {
  height: 30px;
  width: 30px;
}

.blue-loader {
  color: forestgreen;
  font-size: 30px;
}

.thick-hr {
  border-width: 3px;
  max-width: 50px;
  border-color: #528fc8;
}

/* footer section */
.footer {
  /*background-image: url("./assets/single-elements/display-pic.png");*/
  background-position: center center;
  background-size: cover;
}

.number {
  background: url("./assets/single-elements/icons8-phone-50.png") no-repeat
    left center;
  background-size: 20px 20px;
}

.customer-care {
  background: url("./assets/single-elements/customer-service.png") no-repeat
    left center;
  background-size: 20px 20px;
}

.support-mail {
  background: url("./assets/single-elements/icons8-mail-50.png") no-repeat left center;
  background-size: 20px 20px;
}


.number,
.customer-care,
.support-mail {
  padding-left: 50px;
  /* the width of your image + some space */
  margin: 3px 0;
  color: black;
}

.number .customer-care .support-mail,
h6 {
  color: #595959;
}

.footer li {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  color: #dcdcdc;
  letter-spacing: normal;
}

/* .footer ul{
  margin-top: 20px;
} */
/* .footer .list-unstyled.info{
  margin-left: 35px;
} */
.footer li:active {
  color: #4d4d4d !important;
}

.upper-footer-section {
  color: white;
  padding-bottom: 20px;
}

.upper-footer-section p {
  font-size: 10vh;
  font-weight: 600;
}

.btn.button {
  padding: 12px 90px;
  background-color: #980000;
  font-size: 18px;
  letter-spacing: 2px;
}

#search {
  background-color: var(--main-bg-color);
  border-radius: 32px;
  font-size: 16px;
  font-family: lato;
  font-weight: bolder;
  width: fit-content;
  padding: 0px 40px;
}

.middle-footer-section {
  background-color: #000000;
  /*font-weight: 700;*/
  letter-spacing: normal;
  padding: 0px 40px 88px;
}

.footer-text {
  font-size: 90%;
  font-weight: bold;
}
/* fourth section of footer */
.third-footer-section {
  /* background-image: url('./assets/single-elements/slider-img.jpg');
  background-position: center center;
  background-size: cover;  */
  padding-top: 20px;
  color: white;
}

.banners-head {
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  letter-spacing: 0.5px;
}

.banners-head p {
  font-size: 80%;
  color: #a9a9a9;
  font-weight: 500;
}

.bottom-badges {
  display: -webkit-inline-box;
  background-color: whitesmoke;
  padding-top: 5px;
}

.third-footer-section h6 {
  color: white;
}

.third-footer-section::-webkit-input-placeholder {
  color: "#929498";
}

#btn-addon {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  color: #3e5067;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.footer-input {
  background-color: rgba(192, 192, 192, 0.4);
  color: white;
  border-radius: 20px;
  border: none;
}

/* fourth section */
.fourth-footer-section {
  background-color: #242424;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 11px;
  color: #909090;
}

.fourth-footer-section p {
  margin-top: 5px;
}

.list-unstyled.payments li {
  margin-top: 0px;
}

.list-unstyled.payments {
  display: inline-flex;
}

.payment-cards {
  width: 30px;
  margin-left: 5px;
}

/* Garantee */
.garantee {
  background-image: url("./assets/single-elements/bg-things-you-will-love.png");
  padding-top: 2rem;
  padding-bottom: calc(10rem - 56px);
  background-size: cover;
  text-align: center;
}

.garantee img {
  /* width: 40%; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 20px;
}

.garantee-head {
  text-align: center;
}

.garantee-head h2 {
  font-weight: 600;
}

.garantee .row {
  margin-top: 20px;
}

.garantee-head p {
  color: #d00000;
  font-weight: 600;
}

.garantee p,
.link {
  font-size: 11px;
  line-height: 20px;
}

.garantee .link {
  border-bottom: solid 1px#D00000;
}

.garantee i {
  color: #d00000;
}


.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border: solid #cecece;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 6px;
}

.garantee p {
  color: #a9a9a9;
}

.garantee h6 {
  line-height: 10px;
  font-weight: lighter;
}

/* side menu */
ul.sideMenu {
  padding: 0px;
}

ul.sideMenu li {
  list-style-type: none;
  padding: 5px;
  margin: 10px;
  border: 1px solid #cdcdcd;
  color: #6fa8dd;
}

ul.sideMenu li a {
  color: #6fa8dd;
  display: block;
}

.spec td {
  width: 50%;
}

table.borderless td {
  border: none;
}

.table-bordered td, .table-bordered th {
  border-right: 0px;
  border-left: 0px;
}

#navbar-nav {
  display: flex;
  font-weight: 500;
  padding-left: calc((98.5vw - 1110px + 15px) / 2);
}

#second-row-nav {
  color: black;
}

.price {
  color: #000000;
}

.mbtn {
  padding-top: 20px;
}
@media (max-width: 620px) {
  .transparent-polygon {
    margin-left: -2rem !important;
    width: 90% !important;
  }
}

@media (max-width: 992px) {
    #navbar-nav {
    display: none;
  }
  .mr-3 {
    margin-left: 0px !important;
  }

  #navbar-first-row {
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .transparent-polygon {
    margin-left: -5rem;
    width: 80%;
  }

  .home-banner-img {
    display: none;
  }

  .headerbg {
  /*background-image: radial-gradient(circle at center,#fff 0,#cac6c6 100%);*/
  /*background: radial-gradient(ellipse farthest-corner at 0 140%,#722300 0%, #ff4900  70%, var(--main-color) 60%);;*/
    background: radial-gradient(ellipse farthest-corner at 0 150%,#576edc 0%, #3b89ff 60%, var(--main-color) 60%);
  }
  
  .img-form-header {
    color: #000;
  }

  .price-container {
    border-left: 0px solid #f3f3f3;
    padding: 0;
    display: none !important;
  }

  .topPriceContainer {
    display: block !important;
    text-align: left;
  }

  .price {
    font-size: 0.8rem !important;
  }

  #card-mobile-row {
    padding: 10px;
  }
  .upper-nav ul li {
    padding: 0px;
  }

  .navbar-nav {
    margin-top: -6px;
    font-weight: 500;
  }

  .bottom-badges .vertical-line {
    display: none;
  }

  .bottom-badges img {
    width: 30px;
  }

  #cif-price {
    padding: 7px 10px;
  }
  .third-footer-section {
    padding-bottom: 20px;
  }

  .upper-footer-section p {
    font-size: 6vh;
    font-weight: 600;
  }

  .middle-footer-section {
    /*padding: 5px;*/
    padding: 8px 8px 88px 8px;
  }

  .cpanel {
    background-color: #fff;
    /*padding-top: 30px;*/
  }

  .reviewImage {
    width: 120px;
    height: 100px;
    margin: 10px;
    z-index: 2;
  }

  .no-padding-m {
    padding: 0px !important;
  }

  .m {
    border: none;
    background-color: #fff !important;
    font-size: 0.75rem;
  }

  .m table td {
    padding: 5px 0px;
  }

  .mbtn div {
    margin-bottom: 20px;
  }

  .spec {
    width: 100%;
  }

  .spec td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    width: 100%;
  }

  /* .gen-section{
       margin-top: -30px !important;
     } */

  .search-card-img {
    /*height: 70px;*/
    width: 120px;
  }

  .search-card {
    padding: 0px;
  }

  #contactContainer {
    /*padding-left: 10px;*/
  }
  .search-card .col-4 {
    padding: 0px;
  }

  .search-card .col-4 {
    /* margin-left: 5px; */
  }

  .search-card > div {
    border: none;
  }

  .search-card .price {
    font-size: 17px !important;
    font-weight: bold;
  }

  .search-card h5 {
    font-size: 10px !important;
  }

  .np-5 {
    padding: 5px;
  }

  .mt-4 {
    margin-top: 0.5rem !important;
  }

  .breadcrumb {
    font-size: 0.75rem;
  }

  .btn-full {
    width: 100% !important;
  }

  .m-card-body {
    padding: 0px 5px;
  }

  #mainNav {
    position: relative;
  }

  #mainNav .navbar-brand {
    margin: 0px auto;
    position: relative;
    left: 15px;
  }

  .social-icons {
    margin: 5px;
    width: 15px;
    height: 15px;
  }

  .search-card-img {
    background-color: #a9a9a9;
    max-height: 100px !important;
  }
}

.header-section .navbar-dark .navbar-nav .nav-link {
  /* color: rgba(255,255,255,.5); */
  color: black;
  display: none;
}
.navbar-nav {
  padding-left: calc((100vw - 1110px + 15px) / 2);
}
.header-section .navbar-dark .navbar-brand {
  /* color: #fff; */
  color: black;
}
.navbar-brand {
  padding-top: 0px;
}

.item-overview-detail {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

@media (min-width: 768px) {
  .cpanel {
    background-color: #fff;
    /*padding: 30px;*/
  }

  .card-img-top-promo{
  height: 240px !important;
  object-fit: scale-down;
}

  .container {
    max-width: 1110px;
  }

  .inner-container {
    max-width: 1110px;
  }

  .static-info-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .blogs-info-padding {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .item-overview-detail {
    padding-left: 10px !important;
    padding-right: 10px !important;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  /* .social-icons {
    margin-right: 0px;
    width: 20px;
    height: 20px;
  } */
  .reviewImage {
    width: 100%;
    height: 250px;
  }

  .spec {
    width: 100%;
  }
}

@media (max-width: 3000px) {
  .supporters-help-department-heading {
  background-image: url("./assets/single-elements/exyookay-help-support.png");
  background-position: center center;
  background-size: contain;
  height: 300px;
  background-repeat: no-repeat;
}
}
@media (max-width: 938px) {
  .supporters-help-department-heading {
  height: 280px;
}
}

@media (max-width: 879px) {
  .supporters-help-department-heading {
  height: 250px;
}
}

@media (max-width: 785px) {
  .supporters-help-department-heading {
  height: 180px;
}
}

@media (max-width: 551px) {
  .supporters-help-department-heading {
  height: 150px;
}
}

@media (max-width: 400px) {
  .supporters-help-department-heading {
  height: 125px;
}
}

@media (max-width: 330px) {
  .supporters-help-department-heading {
  height: 105px;
}
}
.active-nav {
  left: -15px !important;
}

ul.nav-tabs li {
  cursor: pointer;
   border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  justify-content: center;
}

table.tb-borderless {
  text-align: left;
}

/* Position text in the middle of the page/image */
.bg-text {
  background-color: white;
  /* Fallback color */
  /*background-color: rgba(0, 0, 0, 0.2);*/
  /* Black w/opacity/see-through */
  color: black;
  border-radius: 8px;
  font-weight: bold;
  position: relative;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* z-index: 2; */
  /* width: 80%; */
  padding: 10px 10px 10px 10px;
  /*margin-left: 16px;*/
  text-align: left;
}

.headerbg .col-sm-12 {
  padding-right: 0px;
  padding-left: 0px;
}

.upper-nav {
  background-color: var(--third-bg-color);;
  height: 1px;
  padding: 0px;
}

.card-subtitle {
  padding-bottom: 1rem !important;
}

.card-footer {
  background-color: white !important;
}

.card-text {
  font-size: 1rem;
  font-weight: 600;
}
.card-text:last-child {
  font-size: 1rem;
}

.my-account-image {
  vertical-align: -webkit-baseline-middle;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-right: 0px !important;
}

.hello {
  font-weight: 300;
}

.search-make {
  margin: 4px 0px;
}

.first-nav-item {
  padding-left: 0px !important;
}

.mr-3 {
  margin-left: 12px;
  font-weight: bolder;
  font-size: 90%;
  color: #cbcbcb;
  padding: 10px;
}

a.mr-3:hover {
  color: #fff;
}

.mr-3-first-link {
  margin-left: 0px !important;
  margin-right: 12px;
  font-weight: bolder;
  font-size: 90%;
  color: #cbcbcb;
  padding: 10px;
}
a.mr-3-first-link:hover {
  color: #fff;
}

.pad-link {
  padding: 10px;
}

.arcolades {
  font-size: 85%;
  font-weight: 900;
  color: #858585;
}

.nav-item:hover a {
  color: #626262 !important;
}

.price-container {
  border-left: 1px solid #f3f3f3;
}

.pagination-container {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 2rem 0;
}

.pagination {
  padding-top: 20px;
  text-align: center;
  justify-content: center;
}
.pagination span {
  cursor: pointer;
  color: #616161;
  float: left;
  padding: 6px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  display:inline-block;
}

.pagination span.active {
  background-color: #0099FF;
  color: white;
  border: 1px solid #0099FF;
}


.listedLink {
  color: black;
  font-size: 0.65rem;
  font-weight: 700;
}

.listedSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 12px;
  padding: 10px;
}

@media only screen and (max-width: 530px) {
  .listedSection {
    flex-direction: column;
    padding: 0 15px;
  }

  .card-body {
    padding: 1rem 0.3rem 1rem 0.3rem !important;
    /*display: none;*/
  }

  .cardSearchTitle {
    font-size: 1rem !important;
    letter-spacing: 0px;
  }

  .logo-right {
  font-size: 20px;
  }

  .logo-right-span {
    height: 48px !important;
  }

  .inquiryBtn {
    display: none;
  }

  .footer-div {
    display: none;
  }

  .contentTitles {
    padding-right: 0px !important;
    margin-right: 10px;
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .search-card-img-div {
    margin-top: 6px !important;
    max-width: 120px !important;
    /*margin-left: 10px;*/
  }


  .cardSearch {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }

  .mobile_padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 235px) {

  .logo-right {
  font-size: 16px;
  }

  .logo-right-span {
    height: 32px !important;
  }

}

.topPriceContainer {
  display: none;
}

.filter_Col {
  padding-left: 0px;
}

.filter_Button {
  font-size: 12px;
  background-color: transparent;
  color: #424242 !important;
  font-weight: 500;
  border: 1px solid #e4e4e4;;
}

.select {
  -webkit-appearance: menulist !important;
  -moz-appearance: menuarrow !important;
}

.search-card-img {
  width: auto !important;
  max-height: 200px;
}

#search-card-img {
  width: auto !important;
}

.search-card-img-div {
  width: auto !important;
  max-height: fit-content;
  background-color: white;
  padding: 0 !important;
}

.searchResult {
  background-color: white;
  padding-top: 10px;
}

.cardSearch {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.cardSearchTitle {
  font-size: 1rem;
  color: #000000;
  text-align: left;
  letter-spacing: 0px;
}

.cardProperties {
  font-weight: 500;
  font-size: 0.7rem;
  color: #9c9c9c;
  text-align: left;
  padding: 0px;
}

.ordersCardProperties {
  font-weight: 500;
  font-size: 0.80rem;
  color: #989898;
  text-align: left;
  padding: 0px;
  margin-bottom: 1.5rem
}

.statusCardProperties-pending {
  font-weight: bold;
  font-size: 12px;
  background-color: #ffb42b;
  color: #000000;
  text-align: left;
  padding: 0px;
  border-radius: 4px;
}

.statusCardProperties-authorized {
  font-weight: bold;
  font-size: 12px;
  background-color: #4cb73f;
  color: #000000;
  text-align: left;
  padding: 0px;
  border-radius: 4px;
}

.statusCardProperties-canceled {
  font-weight: bold;
  font-size: 12px;
  background-color: #3e5067;
  color: #efefef;
  text-align: left;
  padding: 0px;
  border-radius: 4px;
}


.inquiryBtn {
  font-size: 12px;
  width: 9vw;
  font-family: 'Lato', sans-serif;;
}

.listedPTag {
  margin-bottom: 0px;
}

.resultBold {
  font-size: 12px;
}

.filter_space {
  /*margin-top: 30px;*/
}

.filter_space_Details {
  /*margin-top: 5px;*/
}

.radio-btn-group {
  display: flex;
  align-items: baseline;
  min-width: 100%;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.formGroupRadios{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.radioLabelParent {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 1.0rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #c5c5c5;
}
.productCode {
  text-align: left;
  padding: 0;
  margin-bottom: 5px;
  font-size: 13px;
  color: #B6B6B6;
}
.dropDowns {
  /*width: 70%;*/
  max-width: 260px;
  margin-bottom: 0.70rem;
  font-weight: bold;
  border: 2px solid var(--secondary-color);
  font-family: "Lato", "Open Sans";
}
.dropdownsContainer {
  display: block;
  align-items: center;
}
.common {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 auto;
}

.radiobtn {
  width: 17px;
  height: 17px;
  border-radius: 8px;
  border: 1px solid #3e5067;
  display: inline-block;
  margin-right: 10px;
  /* background: #fff; */
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.677px;
}

.radioLabel {
  font-size: 13px;
  font-weight: 500;
}

.checked {
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background: #3e5067;
  margin: 0 auto;
}

.unchecked {

}

.containerCheckmark {
  padding-left: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckmark input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 9px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 2px;
  border: 2px solid #A22D2F;
}

/* When the checkbox is checked, add a blue background */
.containerCheckmark input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckmark input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckmark .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 12px;
  border: solid #A22D2F;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.additional {
  text-align: left;
  font-size: 15px;
  font-weight: bolder;
  margin-bottom: 5px;
}
.additionalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0 16px 0;
}

.buyNow {
  min-width: 240px;
  min-height: 40px;
  background: #000000;
  color: #fff;
  border-radius: 8px;
}

.choose-product-btn {
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: linear-gradient(90.27deg, #f926e1 .22%, #3e87fd 99.76%);
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-source: none;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  padding: 5px 60px;
  font-weight: bold;
}

.delete-basket-button {
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-color: black;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  padding: 5px 60px;
  font-weight: bold;
}

.download-button {
  font-family: 'Lato', sans-serif;;
  background-color: #424242;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-repeat: stretch;
  border-image-source: none;
  color: white;
  font-size: 14px;
  border-radius: 30px;
  padding: 5px 60px;
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
}

.card-payment-field {
  border: 1px solid #e2e2e2;
  padding: 8px;
  border-radius: 4px;
}

button:disabled:hover,
button[disabled]{
  background-color: #cccccc;
  color: #000000;
}

.download-media-images {
  height: 200px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.parent {border: 1px solid #f3f3f3; padding: 3px;}
.parent .fixed {background-color: #f1f1f1; color: black; font-weight: 500; font-size: 0.85rem}
.parent .fluid {background-color: #fff; font-size: 0.85rem;}


.decrText {
  flex: 2;
  display: flex;
  padding-left: 20px;
  font-weight: 500;
  font-size: 0.85rem;
  color: #808080;
  padding-bottom: 10px;
  overflow: visible;
}

.carouselTitle {
  width: 100%;
  height: 50px;
  font-size: 22px;
  font-weight: bolder;
}

.viewAllVehicles {
  font-weight: bold;
  text-align: left;
  padding-left: 40px;
  color: #6EB4CC;
  font-size: 13px;
  cursor: 'pointer'
}

.alice-carousel__next-btn-wrapper {
    margin-top: -150px;
}

.alice-carousel__prev-btn-wrapper {
    margin-top: -150px;
}

.descriptionContainer {
  padding-top: 20px;
  padding-left: 5px;
  padding-bottom: 20px;
  width: 90%;
  color: black;
  min-height: 100px;
  word-break: break-word;
}

.descriptionElement {
  /*margin: 20px auto auto;*/
  min-height: 200px;
  /*border: 1px #E7E7E7 solid;*/
}

.productDetailHeader {
  width: 100%;
  height: 45px;
  background: #e7e7e7;
  display: flex;
  align-items: center;
  padding-left: 20px;
}


.form-control {
  padding: 0.275rem 0.55rem;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

#wrapper {
  overflow: hidden;
}

.stockdata {
  clear: both;
  font-weight: 600;
  font-size: 0.8rem;
}

@media only screen and (max-width: 950px) {
    .stockdata {
      float:none;
      width:100%;
      margin-bottom:5px;
      font-weight: 600;
    }
}
@media only screen and (max-width: 576px) {
    .footer-div {
      display: none;
    }
}

@media only screen and (max-width: 400px) {
  .dropDowns {
    width:98%;
  }
  .bg-text {
    padding: 80px 10px 30px 10px
  }

}

.deliveryOptions {
  background-color: #f5f5f5;
  padding-bottom: 0.70rem;
  padding-left: 0.70rem;
  padding-top: 0.70rem;
}
/*border-bottom: 1px solid rgba(0, 0, 0, 0.2);*/
.detailPagePrice {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: red;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: 'Lato', 'Open Sans' ;
  letter-spacing: 0.00938em;
}

.userOrderPreferences {
    /*background-color: whitesmoke;*/
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
}

.hiddenNavItems {
  background-color: whitesmoke;
  font-weight: 600;
  border-top: 1px solid #a9a9a94a;
  font-size: 12px;
}

.card-img-top{
  height: 160px;
  object-fit: scale-down;
}

.card-img-top-item{
  height: 94px;
  object-fit: scale-down;
}

.card-img-top-promo{
  height: 240px !important;
  object-fit: scale-down;
}

.card-img-home-promo {
  height: 280px !important;
  object-fit: contain;
  border-radius: 4px;
  /*border: 1px solid #f0eeee;*/
}

.img-basket {
  width: 100%;
  max-width: 80px;
  height: auto;
  object-fit: scale-down;
  vertical-align: middle;
}

.img-bag {
  width: 100%;
  max-width: 40px;
  height: auto;
  object-fit: scale-down;
  vertical-align: middle;
}

.col-3-custom {
  padding: 0px !important;
}

.img-responsive .card-img-top{
  height: 3vw;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn{
  padding: 1px;
}

.popular-brands {
  max-height: 130px;
  padding: 16px 8px 8px 8px;
  max-width: 140px;
  background-color: white;
  border: solid 1px #0003;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
@media (max-width: 1024px) {
  .popular-brands {
    max-height: 130px;
    /*padding-top: 5px;*/
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .popular-brands {
    max-height: 130px;
    /*padding-top: 5px;*/
  }
}
@media (max-width: 768px) {
  .popular-brands {
    height: 120px;
    padding-top: 5px;
  }
  .card-img-home-promo {
    height: 160px !important;
  }

  .logo-right-span {
    height: 48px !important;
  }

  .static-info-padding {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .blogs-info-padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.popular-img {
  width: 250px;
}
@media (max-width: 768px) {
  .popular-img {
    width: 150px;
    height: 180px;
  }

}
@media (min-width: 1024px) {
  .popular-img {
    width: 108px;
  }
}

.item {
  background-color: white;
  /*margin: 5px;*/
}

.container{
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 30px
  /*box-shadow: 4px 4px 1px #EEE;*/
  /*box-shadow: 0 1px 3px 0 rgba(37,32,31,.3)*/
}

.inner-container{
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px
  /*box-shadow: 4px 4px 1px #EEE;*/
  /*box-shadow: 0 1px 3px 0 rgba(37,32,31,.3)*/
}

.promo-banners {
  margin-top: 1px !important; ;
}

.enter-country {
  font-size: 80% !important;
}

.dealer {
  background-color: white;
}

.home-price{
  color: #606060;
  font-size: 75%;
  font-weight: 900;
  /*font-style: strikethr;*/
}

.sale-price {
  color: #c60a00;
}

.headings-div-successful{
  padding-top: 5px;
  padding-bottom: 0px;
}

.logo-text {
  font-style: italic;
  font-weight: 900;
}

.page-information{
  padding-top: 1rem;
  color: #000000;
}

.basket-page-information{
  padding-top: 0.2rem;
  padding-bottom: 0.25rem;
  color: #000000;
  font-weight: bold;
}

.basket-page-information-header {
  padding-top: 8px !important;
}

.basket-page-information-bottom {
  padding-bottom: 20px !important;
  text-align: center;
}

.jumbotron {
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.checkout-item-title {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 4px 2px 16px 2px;
}

.fa fa-check-circle {
  color: #3e5067;
}

.checkout-info-block {
  padding: 0px;
  margin: 0px;
}

.form-input {
  color: #000000;
}

.checkout-price {
  color: #000000;
  font-weight: bold;
}

.department-heading {
  background-image: url("./assets/single-elements/banner5.jpg");
  background-position: center center;
  background-size: cover;
  height: 200px;
}

.auto-parts-department-heading {
  background-image: url("./assets/single-elements/exyookay-Auto-Parts.jpg");
  background-position: center center;
  background-size: cover;
  height: 250px;
}

.help-department-heading {
  background-image: url("./assets/images/cmct/reg-check-mate-1.png");
  background-position: center center;
  background-size: contain;
  height: 250px;
  background-repeat: no-repeat;
}

.help-department-heading.stock-procurement-heading {
  background-image: url("./assets/images/cmct/reg-check-mate-1.png") !important;
}

.page-not-found {
  background-image: url("./assets/single-elements/missing-page.PNG") !important;
  background-position: center center;
  background-size: contain;
  height: 250px;
  background-repeat: no-repeat;
}

.static-info-page {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 20px;
}

.static-info-page-promo {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 20px;
  background-image: radial-gradient(circle at center,#fff 0,#009898 100%);
}

.popular-brands-card-text {
  font-weight: 500;
  font-size: 10px;
}

.nav-container {

}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.basket-header {
  height: 40px;
  background-color: #f5f5f566;
  margin-bottom: 30px;
}

.col-lg-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.col-lg-2 {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-4 {
  padding-right: 5px;
  padding-left: 5px;
}

.card-body {
  padding: 1.00rem 1.00rem 0.5rem;
}

.link-text {
  color: #5484f4;
  font-weight: 700;
}

.footer-link-text {
  color: lightgrey;
  font-weight: 900;
}

.footer-link-text-div {
  padding-bottom: 7px;
  font-weight: 400;
}

.a-divider.a-divider-section .a-divider-inner {
  height: 20px;
  margin-bottom: -18px;
  background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
  z-index: 0;
  zoom: 1;
}

hr.fancy-line {
    border: 0;
    height: 0.2em;
    position: relative;
    margin: 0.5em 0; /* Keep other elements away from pseudo elements*/
}

hr.fancy-line:before {
    content: '';
    height: 0.5em;
    top: -0.5em;
    z-index: 999;
}


hr.fancy-line, hr.fancy-line:after {
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
    background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%,rgba(0,0,0,0.1)), color-stop(75%,rgba(0,0,0,0)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
    background: -o-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
}

body, hr.fancy-line:before {
    background: #f4f4f4;
}

.help-cards {
  margin-bottom: 10px;
}

.inner-help-cards {
  width: 90%;
  height: 90px;
  margin: auto;
  border: 1px #ddd solid;
  border-radius: 4px;
  padding-top: 10px;
}
.inner-help-cards:hover{
  background-color: #eeeeee;
  background-image: linear-gradient(#ffffff, #ececec)
}

.a-column {
  width: 30%;
  display: inline-block;
  height: 100%;
  overflow: hidden;
}

.b-column {
  width: 65%;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  /*padding-top: 2%;*/
}

.help-cards-img {
  width: 90%;
  padding: 10%;
  object-fit: scale-down;
  max-height: 100%;
  font-style: italic;
  font-weight: 900;
  color: #242424;
}

.help-row {
  background-color: white;
}

.help-cards-list {
  list-style: none;
  padding-left: 0px;
  font-size: 80%;
  color: #bbbbbb;
  font-weight: 500;
}

.help-cards-headings {
  color: #4a4a4a;
}

.bottom-out-space {
  margin-bottom: 50px;
}

.bottom-out-space-padding {
  padding-bottom: 88px;
}

.inner-how-to-cards {
  width: 95%;
  height: 100%;
  margin: auto;
  border: 1px #ddd solid;
  border-radius: 2px;
  padding-top: 25px;
  /*box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px 0px;*/
  box-shadow: rgb(238, 238, 238) 4px 4px 1px;
}

.how-to-cards-img {
  width: 10%;
  object-fit: scale-down;
  max-height: 100%;
  font-style: italic;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #3e5067 !important;

}
.nav-link.active {
   border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: solid 1px #000000;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border-bottom: solid 3px var(--secondary-color);
  font-weight: bold;
  color: var(--main-bg-color);
}

.nav-tabs {
   border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  text-align:center;
}
.nav-pills .nav-link {
  font-size: 80%;
  font-weight: 600;
  color: #424242;
}

.nav-pills:hover .nav-link:hover {
  font-size: 80%;
  font-weight: 600;
  color: #020202;
}

.nav-pills:focus .nav-link:focus {
  font-size: 80%;
  font-weight: 600;
  color: #020202;
}
.nav-pills:focus .nav-link:focus {
  font-size: 80%;
  font-weight: 600;
  color: #020202;
}

a.nav-link:hover {
  background-color: #cccccc !important;
}

a.nav-link.active:hover {
  /*color: #494949 !important;*/
  /*font-size: 12px;*/
}

.tab-content {
  color: #000000;
  background-color: white;
  padding: 40px 5px 40px 5px;
  border-radius: 3px;
}


.accordion-content-header {
  font-size: 80%;
  color: #424242;
  background-color: whitesmoke;
  border-radius: 3px;
}

.accordion-content-body {
  font-size: 80%;
  color: #878787;
  border-radius: 3px;
}

.accordion-content-body-heading {
  font-weight: bold;
}


.panel-title-down::after {
  content: "\f106";
  top: -2px;
  color: #526aae;
  font-weight: 900;
  font-family: "FontAwesome"
}

.panel-title-up::after {
  content: "\f107";
  top: -2px;
  color: #526aae;
  font-weight: 900;
  font-family: "FontAwesome"
}

.panel-title[aria-expanded="true"]::after {
	content: "\f106";
}

.accordion-header-card {
  border-bottom: solid 1px #ccc !important;
}

.accordion-card-body {
  padding: 1rem 1rem 1.5rem;
}

.accordion-body {
  margin-bottom: 5rem;
}

.dropdown-item {
  color: #424242;
  font-weight: 500;
  font-size: 12px;
}

.custom-carousel-button {
  font-size: 0.75rem;
  color: #6fa8dd;
  background-color: transparent;
  border: none;
  font-weight: 500;
}

.custom-carousel-button-wrapper{
  padding: 10px
}

.carousel-wrapper {
  padding-top: 10px;
}

.alert {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
}

.alert-set-destination {
  margin-right: 10px;
}


.basket-checkout-row {
  padding-bottom: 32px;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.a-links {
  color: dodgerblue;
  font-weight: 500
}

.basket-item-title {
  font-weight: bold;
}

.thicker-hr {
  border: none;
  height: 2px;
  color: #333;
  background-color: #e7e7e7;
}

.stepper-background {
  background-color: #f4f4f4;
  height: 80px;
  border-bottom: #e2e2e2 solid 1px;
}

.stepper-spacer {
  height: 20px;
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .4rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ffffff;
  border-radius: 0rem;
  font-size: 0.75rem;

}

.page-header {
  text-align: center;
  font-weight: 900;
}

.free-v-premium {
  width: 100%; /* Optional: Ensures the component takes the full width */
  margin-bottom: 16px; /* Optional: Adds space between components */
  padding: .5rem .25rem 1.5rem .25rem;
  border: 1px solid whitesmoke;
  box-shadow: 0 4px 24px 0 rgb(88 102 126 / 0%), 0 1px 2px 0 rgb(88 102 126 / 13%);
  border-radius: 8px;
  margin-top: 16px;
}

.page-header-content {
  color: var(--secondary-color);
  font-weight: 900;
  padding: 16px 0px;
}

.page-sub-header {
  font-weight: bold;
  padding-bottom: 16px;
}

.MuiTypography-body2 {
  font-family: "Lato", "Open Sans" !important;
  font-size: 1rem !important;
}

.tbl-header {
  font-weight: bold;
  color: #8f8f8f;
  padding: 0.8rem !important;
}

.tbl-data {
  font-weight: bolder;
}

.fat-div {
  padding: 30px 10px 30px 10px;
}

.exp {
  text-align: left;
}

.footer-spacer {
  padding-top: 30px;
  background-color: #252525;
}

.fancy-header-items {
  color: #9c9c9c;
}

.stock-available {
  color: #17740F
}

.stock-unavailable {
  color: #ff2a32;
  font-weight: bold;
}

.info-graphics-heading {
  font-weight: 900;
  padding-bottom: 15px;
}

.how-to-steps {
  /*height: 160px;*/
  /*padding-bottom: 20px;*/
  font-size: 12px;
  color: #3e5067;
  font-weight: 900;
}

.inner-help-heading {
  color: #242424
}

.list-group-item-heading {
  font-size: 12px;
}

.list-group-item-text {
 color: #5484f4;
  font-size: 13px;
}

.react-phone-number-input__country-select{
  color: black
}

.slide-pane__content {
  padding: 14px 3px;
  font-size: 12px
}

.slide-pane_from_left.ReactModal__Content--after-open {
  max-width: 400px;
}

.slide-pane_from_right.ReactModal__Content--after-open {
  max-width: 400px;
}

.pagination a {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
}

.pagination li.active {
  /*background-color: #e20000;*/
  border: 1px solid #7a7a7a;
  border-radius: 2px;
  color: #5484f4 !important;

}
.pagination li.active a {
  color: #5484f4 !important;
}

.pagination a:hover:not(.active) {
  width: 80%;
  border-bottom: 2px solid #7a7a7a;
}

.filter-btn {
  font-family: 'Lato', 'Open Sans';
}

.filter-btn:hover {
  background-color: #f4f3f4 !important;
  color: black;
}

.btn-secondary:hover{
  background-color: #f4f3f4;
  color: black;
}

.btn-secondary{
  background-color: black;
  color: white;
}

.download-button:hover{
  background-color: black;
}

.buyNow:hover{
  background-color: whitesmoke;
  border: 1px solid black;
}

.shop-now:hover{
  background-color: whitesmoke;
}

.filter-button {
  font-family: "Lato", "Open Sans";
  background: var(--secondary-color);
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  border: var(--secondary-color);
  color: #000000;
  font-weight: bold;
  border-radius: 16px;
  padding: 5px 30px;
}

.filter-button:hover {
  background: var(--secondary-color);
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  border: var(--secondary-color);
  font-weight: bold;
  color: #000000;
  font-size: 12px;
  border-radius: 16px;
  padding: 5px 30px;
}

.quantity-picker {
  display: inline-block;
  border:1px solid #dfdfdf;
  border-radius: 3px;
  vertical-align:middle;
}
.quantity-input:focus {
  background: red;
}

.quantity-modifier,
.quantity-display {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

.quantity-modifier {
  height: 100%;
  width: 3rem;
  font-size: 14px;
  background: #f3f3f3;
  color: #888;
  border: 0 solid #dbdbdb;
  text-align: center;
  cursor: pointer;
}
.quantity-modifier:hover {
  background: #dadada;
  color: #555555;
}

.quantity-modifier:focus {
  outline:0;
}

.left-modifier {
  border-radius: 3px 0 0 3px;
}

.mod-disable {
  color:#E0E0E0;
}

.mod-disable:hover {
  background-color: #f3f3f3 !important;
  color:#E0E0E0;
}

.right-modifier {
  border-radius: 0 3px 3px 0;
}

.quantity-display {
  width: 4rem;
  padding: .5rem;
  font-size: 14px;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
}

.supporter-program-text {
  font-size: 15px;
}

.form-separator {
  height: 12px;
  margin: 26px 0 32px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7;
}

.form-separator span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 8px;
    background-color: #fff;
    color: #404145;
}

.MuiCheckbox-colorPrimary {
    color: #007bff;
}

.progress-bar{
  background-color: #3f51b5;
}

.MuiTypography-body1 {
  font-size: 15px !important;
  font-family: 'Lato', 'Open Sans' !important;
  font-weight: 400;
  line-height: 1.5;
}

.MuiBox-root {
  padding: 7px !important;
}

.MuiTabs-root {
  min-width: 95px !important;
}

.MuiTab-root {
  padding: 6px 6px !important;
  text-align: left !important;
}

.MuiTab-wrapper {
  justify-content: left !important;
  align-items: normal !important;
}

.training-headings {
  padding-top: 20px;
}

.questions {
  background-color: cornflowerblue;
  padding: 0.5rem;
  border-radius: 3px 3px 0px 0px;
  margin-bottom: 0rem;
}

.answers {
  padding-top: 1rem;
  background-color: whitesmoke;
}

.react-search-field {
  border-radius: 19px;
  margin: 10px 0 10px 0;
  border: 2px solid black !important;
  height: 38px !important;
}

.react-search-field-input {
  /*border-radius: 10px 0 0 10px;*/
  border-radius: 19px 0 0 19px;
}

.react-search-field-button {
  /*border-radius: 0 10px 10px 0;*/
  border-radius: 0 19px 19px 0;
}

.search-bar-container {
  text-align: center;
  background-color: var(--third-bg-color);
  padding: 32px 0 32px 0;
}

.home-categories {
  height: 400px !important;
  object-fit: cover;
  border-radius: 4px;
}

.home-categories-text {
  color: black;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1.2px;
}

.MuiTypography-body1 {
  /*font-size: 15px !important;*/
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
}

.MuiTypography-h5 {
  font-family: 'Lato', sans-serif !important;
}

.MuiTypography-h6 {
  font-family: 'Lato', sans-serif !important;
}

.MuiTypography-subtitle1 {
  font-family: 'Lato', sans-serif !important;
}

.no-padding-cols {
  padding-left: 3px !important;
}

.MuiSnackbarContent-root {
  background-color: #1c7430 !important;
}

.input-group-text {
  padding: .35rem .75rem !important;
  background-color: #a61930 !important;
  border: 2px solid #a61930 !important;
}

.form-control:disabled, .form-control[readonly] {
  border: 3px solid #a7a7a7;
  color: #3b3a3a !important;
}

.payment-methods {
  border: 1px solid #f0ecec;
  border-radius: 4px;
  margin: 16px 4px 32px 4px;
  background-color: whitesmoke;
  padding: 16px 0px 16px 0px;
}

.small-print {
  font-size: 80%;
}

.dropdown-toggle {
  border-radius: 0px;
}

.success-report {
  background-color: #20cc88;
  color: #1d351d;
  border-radius: 8px;
  padding: 16px;
  /*border: 2px solid #35c935*/
}

.failure-report {
  background-color: #f3a0a0;
  border-radius: 8px;
  padding: 16px;
  color: #813030;
  /*border: 2px solid #ef6767*/
}

.neutral-report {
  background-color: whitesmoke;
  color: #000000;
  border-radius: 8px;
  padding: 16px;
  border: 2px solid #e5e5e5;
}

.report-div {
  margin-bottom: 16px;
}

.tax-mot-headers {
  text-align: left;
  font-size: 1rem;
}

.mot-defects-header {
  font-size: 1rem;
  font-weight: 900;
  color: #ab3031;
  text-align: center;
  display: block;
  width: 100%;
  padding: 1rem;
}

.mot-advisory-header {
  font-size: 1rem;
  font-weight: 900;
  color: #ab8430;
  text-align: center;
  display: block;
  width: 100%;
  padding: 1rem;
}

.tax-mot-summary {
  text-align: right;
}

.summary-boxes-success {
  padding: 1.25rem;
  border: 1px solid whitesmoke;
  border-radius: 8px;
  margin-bottom: 3rem;
  border-left: solid 0.25rem #23cc88;
}

.summary-boxes-failure {
  padding: 1.25rem;
  border: 1px solid whitesmoke;
  border-radius: 8px;
  margin-bottom: 3rem;
  border-left: solid 0.25rem #d77474;;
}

.tax-mot-header-text {
  font-weight: bolder !important;
  font-size: 1.2rem !important;
}

.general-veh-sect {
  padding: 2rem 0 1.5rem 0;
}

.report-meta {
  font-size: 12px;
  color: #292929;
}

.mot-meta-header {
  color: #58667e78;
  font-size: 80%;
}

.mot-failure {
  display: block;
  width: 100%;
  /*background: #f58888;*/
  background: #f3a0a0;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  color: #462525;
  font-weight: bolder;
}

.mot-pass {
  display: block;
  width: 100%;
  background: #2c8;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  color: #0f380f;
  font-weight: bolder;
}

.mot-advisory {
  display: block;
  width: 100%;
  background: #f5d688;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  color: #584515;
  font-weight: bolder;
}

.mot-meta {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 16px;
}

.report-plate {
  background-color: yellow;
  padding: 4px;
  border-radius: 4px;
  border: 2px solid #363636;
  color: black !important;
  font-weight: bold;
  font-family: UKNumberPlate, sans-serif;
}

.mot-date {
  font-weight: 900;
  color: cornflowerblue;
  font-size: 1.2rem;
  padding-bottom: 16px;
}

.mot-component-container {
  display: block;
  width: 100%; /* Optional: Ensures the component takes the full width */
  margin-bottom: 16px; /* Optional: Adds space between components */
  padding: 1.5rem 1.25rem 2.5rem 1.25rem;
  border: 1px solid whitesmoke;
  box-shadow: 0 4px 24px 0 rgba(88,102,126,.08),0 1px 2px 0 rgba(88,102,126,.12);
  border-radius: 8px;
  margin-top: 16px;
}

.report-headers {
  font-weight: bolder !important;
  padding-bottom: 32px;
  margin-bottom: 2rem !important;
  padding-top: 16px;
  font-size: 1.2rem !important;
  color: black !important;
  background-color: #f9faff !important;
}

.ai-confirm-headers {
  font-weight: bolder !important;
  padding-bottom: 24px;
  margin-bottom: 1rem !important;
  padding-top: 16px;
  font-size: 1.2rem !important;
  color: black !important;
}

.ai-text {
  color: #393939 !important;
}

.ai-headers {
  text-align: center;
}

.typo-text {
  font-weight: bolder !important;
}

.key-data {
  text-align: left !important;
}

.value-data {
  text-align: right !important;
}

.hr-data {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.premium {
  background-color: #4cf673;
  border-radius: 4px;
  padding: 4px;
}

.basic {
  background-color: #0794f8;
  border-radius: 4px;
  padding: 4px;
}

.blurred {
  filter: blur(5px); /* Adjust the blur amount as needed */
}

